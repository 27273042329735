import { render, staticRenderFns } from "./ContractApproval.vue?vue&type=template&id=1a812eb0&scoped=true"
import script from "./ContractApproval.vue?vue&type=script&lang=js"
export * from "./ContractApproval.vue?vue&type=script&lang=js"
import style0 from "./ContractApproval.vue?vue&type=style&index=0&id=1a812eb0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a812eb0",
  null
  
)

export default component.exports