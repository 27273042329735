<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table style="width: 100%;">
                        <tr>
                            <td class="td-text">
                                申请编号：
                            </td>
                            <td class="td-input">
                                <el-input v-model="searchItem.applyNum" size="small" placeholder="请输入协议申请编号"></el-input>
                            </td>
                            <td class="td-text">
                                销售人员：
                            </td>
                            <td class="td-input">
                                <el-select v-model="searchItem.userName" size="small"
                                           style="width: 100%"
                                           filterable
                                           placeholder="请选择销售人员">
                                    <el-option
                                            v-for="item in saleList"
                                            :key="item"
                                            :label="item"
                                            :value="item">
                                    </el-option>
                                </el-select>
                            </td>
                            <td class="td-text">
                                联系电话：
                            </td>
                            <td class="td-input">
                                <el-input v-model="searchItem.userTel" size="small" placeholder="请输入联系电话"
                                ></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-text" style="padding-top: 10px">
                                申请时间：
                            </td>
                            <td class="td-input" style="padding-top: 10px">
                                <el-date-picker
                                        v-model="searchItem.startDate"
                                        type="date"
                                        size="small"
                                        placeholder="选择日期时间"
                                        style="width: 47.2%"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                                至
                                <el-date-picker
                                        v-model="searchItem.endDate"
                                        type="date"
                                        placeholder="选择日期时间"
                                        size="small"
                                        style="width: 47.5%;"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </td>
                            <td class="td-text" style="padding-top: 10px">
                                公司名称：
                            </td>
                            <td class="td-input" style="padding-top: 10px">
                                <el-input v-model="searchItem.companyName" size="small" placeholder="请输入公司名称"
                                ></el-input>
                            </td>
                            <td class="td-text" style="padding-top: 10px">
                                公司类型：
                            </td>
                            <td class="td-input" style="padding-top: 10px">
                                <template>
                                    <el-select v-model="searchItem.companyType" size="small" style="width: 100%;"
                                               placeholder="请选择公司类型">
                                        <el-option
                                                v-for="item in unitTypes"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </template>
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align: right;padding-top: 10px">项目名称：</td>
                            <td style="padding-top: 10px">
                                <el-input v-model="searchItem.projectName" size="small" placeholder="请输入项目名称"
                                ></el-input>
                            </td>
                            <td></td>
                            <td></td>
                            <td style="padding-top: 10px;text-align: center" colspan="2">
                                <el-button type="primary" icon="el-icon-search" size="small"
                                           @click="searchBtn">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh-right" style="margin-left: 25%"
                                           size="small" @click="resetBtn">重置
                                </el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '45px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
                            <el-table-column prop="applyNum" label="申请编号" width="150" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <!-- <el-table-column prop="testType" label="协议类型" width="120" align="center"></el-table-column>-->
                            <el-table-column prop="userName" label="销售人员" width="100" align="center"></el-table-column>
                            <el-table-column prop="userTel" label="联系电话" width="110" align="center"></el-table-column>
                            <el-table-column prop="towerCount" label="塔机" width="70" align="center"></el-table-column>
                            <el-table-column prop="lifterCount" label="升降机" width="70" align="center"></el-table-column>
                            <el-table-column label="合同类型" width="100" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.contractType == 0">销售合同</span>
                                    <span v-else-if="scope.row.contractType == 1">技术服务合同</span>
                                    <span v-else-if="scope.row.contractType == 2">部件买卖合同</span>
                                    <span v-else-if="scope.row.contractType == 3">售后合同</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="companyName" label="公司名称" width="240"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="companyType" label="公司类型" width="100" align="center">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.companyType == 0">建机厂</span>
                                    <span v-if="scope.row.companyType == 1">国企</span>
                                    <span v-if="scope.row.companyType == 2">租赁商</span>
                                    <span v-if="scope.row.companyType == 3">代理商</span>
                                    <span v-if="scope.row.companyType == 4">集团公司</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="projectName" label="项目名称" width="140" show-overflow-tooltip
                                             align="left">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.projectName == ''||scope.row.projectName==undefined">无</span>
                                    <span v-else>{{scope.row.projectName}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="createTime" label="申请时间" :formatter="timeFormat" width="110" align="center"></el-table-column>
                            <el-table-column prop="auditState" label="审批状态" width="100" align="center">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.auditState == 1" style="color: blue">待审批</span>
                                    <span v-else-if="scope.row.auditState == -1" style="color: red">已驳回</span>
                                    <span v-else style="color: green">已审批</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="remark" label="备注" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="id" label="操作" width="150" align="center">
                                <template slot-scope="scope">
                                    <div class="opertion">
                                        <i class="iconfont iconbohui" title="驳回合同"
                                           v-if="scope.row.auditState == 1 && hasAuthority('contract_approval_convert')"
                                           @click="refuseContractApproval(scope.row.id)"></i>
                                        <i class="iconfont iconxiangqing" title="查看详情"
                                           @click="showContract(scope.row.id)"></i>
                                        <i class="iconfont iconshengchenghetong" title="生成合同"
                                           v-if="scope.row.auditState == 1 && hasAuthority('test_approval_convert')"
                                           @click="createFormalContract(scope.row.id)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="data-dialog">
            <el-dialog
                    title="编辑合同申请"
                    :visible.sync="dialogVisible"
                    :close-on-click-modal="false"
                    width="50%"
                    center>
                <el-form :model="contractForm" :rules="rules" ref="contractForm" label-width="100px"
                         class="demo-ruleForm">
                    <el-form-item size="small" label="协议名称:" prop="name">
                        <el-input size="small" v-model="contractForm.name"></el-input>
                    </el-form-item>
                    <el-row>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item size="small" label="协议号:" prop="number">
                                <el-input size="small" v-model="contractForm.number"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item size="small" label="公司名称:" prop="companyName">
                                <el-input size="small" v-model="contractForm.companyName"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item size="small" label="公司类型:" prop="unitType">
                                <el-select v-model="contractForm.unitType" placeholder="请选择公司类型">
                                    <el-option
                                            v-for="item in unitTypes"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item size="small" label="设备类型:" prop="isTaxpayer" style="text-align: center">
                                <el-radio v-model="contractForm.equipType" label="1">加强型</el-radio>
                                <el-radio v-model="contractForm.equipType" label="2">普通型</el-radio>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item size="small" label="数量:" prop="quality">
                                <el-input size="small" v-model.number="contractForm.quality"
                                          autocomplete="off"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item size="small" label="是否安装:" prop="install" style="text-align: center">
                                <el-switch
                                        size="small" v-model="contractForm.install"
                                        active-color="#14ce66"
                                        active-text="安装"
                                        inactive-color="#ff4949"
                                        inactive-text="不安装" align="center">
                                </el-switch>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item size="small" label="客户需求:" prop="demand">
                        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">
                            全选
                        </el-checkbox>
                        <!--  <el-checkbox-group v-model="checkedFunctions " @change="handleCheckedFunctionsChange">-->
                        <el-checkbox-group v-model="contractForm.demand " @change="handleCheckedFunctionsChange">
                            <el-checkbox v-for="fun in functions"
                                         :label='fun'
                                         :key="fun">{{fun}}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-row>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item size="small" label="开始日期:" prop="number">
                                <el-date-picker
                                        v-model="contractForm.startDate"
                                        type="date"
                                        placeholder="选择日期时间">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" style="width: 50%">
                            <el-form-item size="small" label="开始日期:" prop="number">
                                <el-date-picker
                                        v-model="contractForm.endDate"
                                        type="date"
                                        placeholder="选择日期时间"
                                        default-time="23:59:59">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item size="small" label="备注:" prop="remark">
                        <el-input type="textarea" size="small" :rows="3" v-model="contractForm.remark"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="information">
            <el-dialog
                    :title="showTitle"
                    :visible.sync="infoVisible"
                    width="70%"
                    :close-on-click-modal="false"
                    style="margin-top: -90px"
                    center>
                <div class="dialog-body">
                    <div class="basic-info">
                        <div class="text-title">
                            基础信息
                        </div>
                        <div>
                            <table style="text-align: left;width: 100%">
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        公司名称：
                                    </td>
                                    <td style="width: 300px;">
                                        {{contractDisplay.companyName}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">申请编号：</td>
                                    <td style="width: 170px">
                                        {{contractDisplay.applyNum}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">合同类型：</td>
                                    <td style="width: 300px">
                                        {{contractDisplay.contractType == 0 ? '销售合同':contractDisplay.contractType == 1 ? '技术服务合同':contractDisplay.contractType == 2 ? '部件买卖合同':contractDisplay.contractType == 3 ? '售后合同':'未知合同'}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        公司类型：
                                    </td>
                                    <td style="width: 300px;">
                                        {{contractDisplay.companyType==0?'建机厂':(contractDisplay.companyType==1?'国企':(contractDisplay.companyType==2?'租赁商':(contractDisplay.companyType==3?'代理商':'集团公司')))}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">塔机数量：</td>
                                    <td style="width: 170px">
                                        {{contractDisplay.towerCount}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">升降机数量：</td>
                                    <td style="width: 170px">
                                        {{contractDisplay.lifterCount}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        是否安装：
                                    </td>
                                    <td style="width: 300px;">
                                        {{contractDisplay.isInstalled == 0?"否":"是"}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">项目名称：</td>
                                    <td style="width: 170px">
                                        {{contractDisplay.projectName}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">服务周期(月)：</td>
                                    <td style="width: 170px">
                                        {{contractDisplay.startDate}}~{{contractDisplay.endDate}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        设备类型：
                                    </td>
                                    <td style="width: 300px;">
                                        {{contractDisplay.deviceType == 1?"加强型":"普通型"}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        备注：
                                    </td>
                                    <td style="width: 300px;" colspan="5">
                                        {{contractDisplay.remark}}
                                    </td>
                                </tr>
                                <tr v-if="contractDisplay.contractType != 2">
                                    <td>功能需求：</td>
                                    <td colspan="5" style="padding: 10px 0">
                                        <div class="td-body">
                                            <table cellpadding="0" cellspacing="0" style="width: 100%">
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                        序号
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in towerItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.index}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;text-overflow:ellipsis;padding: 3px; text-align: center">
                                                        塔机
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in towerItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.text}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        数量
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in towerItems"
                                                        style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                        {{item.quality}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                        升降机
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in lifterItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.text}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        数量
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in lifterItems"
                                                        style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                        {{item.quality}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                        特殊型号
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in specialItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.text}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        数量
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in specialItems"
                                                        style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                        {{item.quality}}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-else>
                                    <td>部件需求：</td>
                                    <td colspan="5" style="padding: 10px 0">
                                        <div class="td-body">
                                            <table cellpadding="0" cellspacing="0" style="width: 100%">
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                        部件名称
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in componentItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.text}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        数量
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in componentItems"
                                                        style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                        {{item.quality}}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <template>
                                <el-table
                                        :data="basicTable"
                                        style="width: 100%"
                                        border
                                        :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                        :cell-style="{padding:'0',height: '41px'}">
                                    <el-table-column type="index" label="序号" width="50px" align="center"></el-table-column>
                                    <el-table-column prop="deviceCategory" label="设备类型" align="center">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.deviceCategory == 1">升降机</span>
                                            <span v-else>塔机</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="sizeModel" label="规格型号" align="center"></el-table-column>
                                    <el-table-column prop="siteNum" label="现场编码" align="center"></el-table-column>
                                    <el-table-column prop="stringDiameter" label="钢丝绳直径(mm)"
                                                     align="center"></el-table-column>
                                    <el-table-column prop="armLength" label="前臂长度(m)" align="center"></el-table-column>
									<el-table-column prop="rearArmLength" label="后臂长度(m)" align="center"></el-table-column>
                                    <el-table-column prop="maxLoad" label="最大载重(T)" align="center"></el-table-column>
                                    <el-table-column prop="deviceType" label="类型" align="center"></el-table-column>
                                    <el-table-column prop="isNeedCard" label="是否需要视频流量卡" align="center">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.isNeedCard == 1">是</span>
                                            <span v-else>否</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="isNeedModel" align="center" label="是否需要现场调试多塔防碰撞功能">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.isNeedModel == 1">是</span>
                                            <span v-else>否</span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                        </div>
                        <div class="upload-files">
                            <div class="text-title">
                                附件信息
                            </div>
                            <div class="file-type">
                                <div style="width:8%;padding: 10px">
                                    设备型号清单:
                                </div>
                                <div style="width: 6%">
                                    <el-tooltip class="item" effect="dark" content="点此上传设备信息清单" placement="top-start">
                                        <el-button type="text" v-if="isPass == 1" @click="uploadList(0)">点此上传
                                        </el-button>
                                        <el-button type="text" v-else disabled>点此上传</el-button>
                                    </el-tooltip>
                                </div>
                                <div style="width: 87%;padding: 12px">
                                    <span>{{contractDisplay.deviceListUrl != null?contractDisplay.deviceListUrl.split('/').slice(-1) + '':''}}</span>
                                </div>
                            </div>
                            <div class="file-type">
                                <div style="width: 8%;padding: 10px">
                                    项目信息清单:
                                </div>
                                <div style="width: 6%">
                                    <el-tooltip class="item" effect="dark" content="点此上传项目信息清单" placement="top-start">
                                        <el-button type="text" v-if="isPass == 1" @click="uploadList(1)">点此上传
                                        </el-button>
                                        <el-button type="text" v-else disabled>点此上传</el-button>
                                    </el-tooltip>
                                </div>
                                <div style="width: 87%;padding: 12px">
                                    <span>{{contractDisplay.projectMsgUrl != null?contractDisplay.projectMsgUrl.split('/').slice(-1) + '':''}}</span>
                                </div>
                            </div>
                            <div class="file-type">
                                <div style="width: 8%;padding: 10px">
                                    其它附件:
                                </div>
                                <div style="width: 6%">
                                    <el-tooltip class="item" effect="dark" content="点此上传其它附件" placement="top-start">
                                        <el-button type="text" v-if="isPass == 1" @click="uploadList(2)">点此上传
                                        </el-button>
                                        <el-button type="text" v-else disabled>点此上传</el-button>
                                    </el-tooltip>
                                </div>
                                <div style="width: 87%;padding: 12px">
                                    <span>{{contractDisplay.otherAttachUrl != null?contractDisplay.otherAttachUrl.split('/').slice(-1) + '':''}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="normal" @click="infoVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitAndSave">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="upload">
            <el-dialog
                    :title="uploadTitle"
                    :visible.sync="fileDialog"
                    width="410px"
                    :close-on-click-modal="false"
                    center>
                <el-upload
                        class="upload-demo"
                        drag
                        ref="upload"
                        style="width: 100%"
                        :action='url'
                        :before-upload="handleBeforeUpload"
                        :on-success="getSuccessData"
                        :on-error="getErrorData"
                        multiple>
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__tip" slot="tip">{{tipMessage}}</div>
                </el-upload>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelFile">取 消</el-button>
                    <el-button type="primary" @click="submitFile">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import funOptions from '../../../public/json/funOptions.json'
    import basicData from '../../../public/json/basicData.json'

    var pathURL = process.env.VUE_APP_CURENV;

    export default {
        data() {
            return {
                pageList: [14, 20, 30, 50],
                tableHeight: '',
                uploadTitle: '',
                url: '',
                lineHeight: '',
                fileDialog: false,
                filePath1: '',
                filePath2: '',
                filePath3: '',
                isPass: 0,
                contractId: '',
                uploadType: '',
                showTitle: '',
                basicTable: [],
                checkAll: false,
                functions: [],
                funNameList: [],
                isIndeterminate: false,
                dialogVisibles: false,
                shippingVisible: false,
                infoVisible: false,
                multipleSelection: [],
                approvalStatuses: [],
                towerItems: [],
                lifterItems: [],
                componentItems:[],
                specialItems: [],
                delList: [],
                saleList: [],
                // totalPage:0,
                searchItem: {
                    applyNum: '',
                    userName: '',
                    userTel: '',
                    startDate: '',
                    endDate: '',
                    companyName: '',
                    projectName: '',
                    companyType: null,
                    pageSize: 14,
                    pageIndex: 1
                },
                totalPage: 30,
                dialogVisible: false,
                option: {
                    pageIndex: 1,
                    pageSize: 14,
                    currentPage: 2
                },
                unitTypes: [
                    {
                        label: '建机厂',
                        value: 0
                    },
                    {
                        label: '国企',
                        value: 1
                    },
                    {
                        label: '租赁商',
                        value: 2
                    },
                    {
                        label: '代理商',
                        value: 3
                    },
                    {
                        label: '集团公司',
                        value: 4
                    },
                ],
                UpDialogVisible: false,
                tableData: [],
                contractForm: {
                    name: '',
                    companyName: '',
                    unitType: '',
                    equipType: '',
                    quality: '',
                    install: true,
                    demand: '',
                    contractType: '',
                    startDate: '',
                    contractCycle: '',
                    projectName: '',
                    endDate: '',
                    other: ''
                },
                contractDisplay: {
                    applyNum: "",
                    createTime: "",
                    auditState: 0,
                    companyName: "",
                    companyType: 0,
                    contractType: '',
                    projectMsgUrl: '',
                    otherAttachUrl: '',
                    deviceListUrl: '',
                    customerId: null,
                    deviceType: '',
                    endDate: "",
                    id: "",
                    lifterCount: 2,
                    lifterMap: null,
                    remark: "",
                    startDate: "",
                    towerCount: 2,
                    towerMap: "",
                    userName: "",
                    userTel: "",
                    projectName: '',
                    isInstalled: 0
                },
                contractFile: {
                    id: '',
                    projectMsgUrl: '',
                    otherAttachUrl: '',
                    deviceListUrl: '',
                },
                tipMessage: '',
                isSalesman: '',
                isClose: false,
                rules: {
                    name: [{required: true, message: '协议名称不能为空', trigger: 'blur'}],
                    number: [{required: true, message: '协议号不能为空', trigger: 'blur'}],
                    companyName: [{required: true, message: '公司名称不能为空', trigger: 'blur'}],
                    startDate: [{required: true, message: '开始时间不能为空', trigger: 'blur'}],
                    endDate: [{required: true, message: '截止时间不能为空', trigger: 'blur'}],
                    quality: [{required: true, message: '数量不能为空', trigger: 'blur'}]
                },

            }
        },
        methods: {
            timeFormat(row, column) {
                let data = row[column.property]
                let dt = new Date(data)
                var year = dt.getFullYear();
                var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                return year + '-' + month + '-' + date
            },
            pageChange(option) {
                if(option != "refresh"){
                    this.option.pageIndex = option;
                    this.searchItem.pageIndex = option;
                }
                this.$api.pageContractApproval(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })

            },
            chooseSelection(val) {
                this.multipleSelection = val;
            },
            pageSizeChange(val) {
                this.searchItem.pageSize = val;
                this.$api.pageContractApproval(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            resetBtn() {
                this.searchItem = {
                    applyNum: '',
                    userName: '',
                    userTel: '',
                    startDate: '',
                    endDate: '',
                    companyName: '',
                    projectName: '',
                    companyType: null,
                    pageSize: 14
                },
                    this.searchBtn();
            },
            editContract(contract) {
                this.title = '编辑协议页面';
                this.dialogVisible = true;
                this.contractForm = {
                    name: contract.name,
                    number: contract.number,
                    companyName: contract.companyName,
                    unitType: contract.unitType,
                    equipType: contract.equipType,
                    quality: contract.quality,
                    install: contract.install,
                    demand: contract.demand,
                    contractType: contract.contractType,
                    startDate: contract.startDate,
                    projectName: contract.projectName,
                    endDate: contract.endDate,
                    contractCycle: contract.contractCycle,
                    other: contract.other
                }
            },
            exportExcel() {

            },
            searchBtn() {
                this.pageChange(1)
            },
            uploadFiles(id) {
                this.dialogVisibles = true;
                this.contractId = id;
                this.uploadType = 'contractApproval'
            },
            openDialog(val) {
                this.dialogVisibles = !this.dialogVisibles
            },
            showContract(id) {
                this.infoVisible = true;
                this.towerItems = [];
                this.lifterItems = [];
                this.componentItems = [];
                this.$api.getContractApprovalById({id}).then(res => {
                    if (res.code == 200) {
                        this.contractDisplay = {
                            applyNum: res.data.applyNum,
                            createTime: res.data.createTime,
                            auditState: res.data.auditState,
                            companyName: res.data.companyName,
                            companyType: res.data.companyType,
                            contractType: res.data.contractType,
                            customerId: res.data.customerId,
                            deviceType: res.data.deviceType,
                            endDate: res.data.endDate,
                            projectMsgUrl: res.data.projectMsgUrl,
                            otherAttachUrl: res.data.otherAttachUrl,
                            deviceListUrl: res.data.deviceListUrl,
                            id: res.data.id,
                            lifterCount: res.data.lifterCount,
                            lifterMap: res.data.lifterMap,
                            remark: res.data.remark,
                            startDate: res.data.startDate,
                            towerCount: res.data.towerCount,
                            towerMap: res.data.towerMap,
                            userName: res.data.userName,
                            userTel: res.data.userTel,
                            projectName: res.data.projectName,
                            isInstalled: res.data.isInstalled,
                        };
                        this.contractFile = {
                            id: res.data.id,
                            projectMsgUrl: res.data.projectMsgUrl,
                            otherAttachUrl: res.data.otherAttachUrl,
                            deviceListUrl: res.data.deviceListUrl,
                        }
                        this.isPass = res.data.auditState;
                        this.basicTable = res.data.deviceList;
                        this.showTitle = '《' + res.data.applyNum + '》详情信息'
                        var towerItemsStr = res.data.towerMap;
                        if (towerItemsStr) {
                            var funcItem = {};
                            var index = 1
                            for (var i in towerItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text =  i;
                                funcItem.index = index;
                                funcItem.quality = towerItemsStr[i];
                                this.towerItems.push(funcItem);
                                index = index + 1
                            }
                        }
                        var lifterItemsStr = res.data.lifterMap;
                        if (lifterItemsStr) {
                            var funcItem = {};
                            for (var i in lifterItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text =  i;
                                funcItem.quality = lifterItemsStr[i];
                                this.lifterItems.push(funcItem);
                            }
                        }
                        var specialItemsStr = res.data.specialMap;
                        if (specialItemsStr) {
                            var funcItem = {};
                            for (var i in specialItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text =  i;
                                funcItem.quality = specialItemsStr[i];
                                this.lifterItems.push(funcItem);
                            }
                        }
                        var componentItemsStr = res.data.componentMap;
                        if (componentItemsStr) {
                            var funcItem = {};
                            for (var i in componentItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text =  i;
                                funcItem.quality = componentItemsStr[i];
                                this.componentItems.push(funcItem);
                            }
                        }
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            createFormalContract(id) {
                this.$confirm('你确定要生成该申请信息吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.createContract({id: id}).then(res => {
                        if (res.code == 200) {
                            this.pageChange("refresh")
                            this.$message.success(res.message)
                            this.$router.push("/contract?id=" + id)
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                }).catch(() => {
                })
            },
            handleBeforeUpload(file) {
                const typeList = ["xls", "xlsx"];
                const errMsg = '请上传excel文件'
                if (this.flag == 0 || this.flag == 1) {
                    return this.beforeUpload(file, typeList, errMsg)
                }
            },
            beforeUpload(file, arr = [], mssage) {
                const typeList = [...arr];
                const fileName = file.name;
                const extension = fileName.substr(fileName.lastIndexOf(".") + 1);
                const isRT = typeList.includes(extension);
                if (!isRT) {
                    this.$message.error(mssage);
                }
                return isRT;
            },
            uploadList(val) {
                this.url = pathURL + 'erp/file/upload?businessType=list&access_token=' + localStorage.getItem("accessToken")
                this.fileDialog = true
                if (val == 0) {
                    this.uploadTitle = '上传设备型号清单';
                    this.tipMessage = '只能上传excel文件';
                    this.flag = 0
                } else if (val == 1) {
                    this.uploadTitle = '上传项目信息清单'
                    this.tipMessage = '只能上传excel文件';
                    this.flag = 1
                } else {
                    this.uploadTitle = '上传其它附件'
                    this.tipMessage = '可以上传*.xlsx,*.xls,*.doc,*.png等';
                    this.flag = 2
                }
            },
            getSuccessData(res) {
                if (res.code == 200) {
                    if (this.flag == 0) {
                        this.contractFile.deviceListUrl = res.data.filePath
                        this.contractDisplay.deviceListUrl = res.data.filePath
                    } else if (this.flag == 1) {
                        this.contractFile.projectMsgUrl = res.data.filePath
                        this.contractDisplay.projectMsgUrl = res.data.filePath
                    } else {
                        this.contractFile.otherAttachUrl = res.data.filePath
                        this.contractDisplay.otherAttachUrl = res.data.filePath
                    }
                } else {
                    this.$message.error(res.message)
                }
            },
            getErrorData(res) {
                this.$message.error(res.message)
            },
            cancelFile() {
                this.fileDialog = false;
                this.$refs.upload.clearFiles();
            },
            submitFile() {
                this.fileDialog = false;
                this.$refs.upload.clearFiles();
            },
            submitAndSave() {
                if (this.isPass != 10) {
                    this.$api.analysisAboutContract(this.contractFile).then(res => {
                        if (res.code == 200) {
                            this.infoVisible = false;
                            this.$message.success(res.message)
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                } else {
                    this.infoVisible = false;
                }
            },
            refuseContractApproval(id) {
                this.$confirm('你确定要驳回该申请信息吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.createContract({id: id, isReject: 1}).then(res => {
                        if (res.code == 200) {
                            this.pageChange("refresh")
                            this.$message.success(res.message)
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                }).catch(() => {
                })
            },

            handleCheckAllChange(val) {
                this.contractForm.demand = val ? this.functions : [];
                this.isIndeterminate = false;
            },
            handleCheckedFunctionsChange(value) {
                let checkedCount = value.length;
                this.checkAll = checkedCount === this.functions.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.functions.length;
            },
            addContract() {
                this.dialogVisible = true;
                this.ContractForm = {
                    name: '',
                    companyName: '',
                    unitType: '',
                    equipType: '1',
                    quality: '',
                    install: true,
                    demand: [],
                    ContractType: '',
                    startDate: '',
                    endDate: '',
                    ContractCycle: '',
                    other: ''
                }
            }
        },
        created() {
            this.searchBtn();
            this.$api.getSaleList().then(res => {
                if (res.code == 200){
                    this.saleList = res.data
                }
            })
            this.isSalesman = localStorage.getItem("isSalesman");
            this.basicTable = basicData.data;
            this.functions = funOptions.data;
            this.tableHeight = (window.innerHeight) * 0.67
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        }
    }
</script>

<style lang="scss" scoped>
    .text-title {
        font-size: 16px;
        font-weight: bold;
        color: #437EE6;
        text-align: center;
        margin-bottom: 20px;
    }

    .dialog-body {
        max-height: 650px;
        overflow-y: scroll;
    }

    .opertion {
        display: flex;
        justify-content: center;
    }

    .iconfont {
        font-family: "iconfont" !important;
        font-size: 20px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }


    .iconshengchenghetong {
        margin-left: 20px;
    }

    .iconbohui {
        color: red;
        margin-right: 20px;
    }


    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 17%;
        margin-top: 10px;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 5px;
    }

    .table-info {
        height: 94%;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 10px;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .handle-input {
        width: 380px;
        display: inline-block;
    }

    .handle-input-date {
        width: 150px;
        display: inline-block;
        padding-right: 0px;
    }

    .information {
        background-color: #F0F2F5;
    }

    .dialog-title {
        margin: 0 20px;
        color: #67ADD9;
        font-size: 20px;
        /*border: 1px red solid;*/
    }

    .payment-table, .approval-table {
        margin-top: 10px;
    }


    .td-body {
        padding: 10px 0;
        white-space: nowrap;
        overflow-x: scroll;
        width: 1100px;
    }

    td {
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }

    .upload-files {
        margin-top: 50px;
    }

    .file-type {
        margin-top: 10px;
        width: 100%;
        display: flex;
    }

    .td-text {
        width: 90px;
        text-align: right;
    }

    .td-input {
        width: 28%;
    }
</style>
